import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getRequest } from "utils/httpClient";
import { useAuth } from "hooks/auth";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getGroupState, getGroupStatus, requestToJoinGroup, } from "redux/slices/group-slice";
import PoolItem from "components/pool-item/pool-item";
import styles from "assets/shared-stylesheets/sparket-list-item.module.css";
import Header from "components/header";
import LoadingSpinner from "components/loading-spinner.tsx";
import Headroom from "react-headroom";
import { Pool } from "interfaces/pool";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import "react-bootstrap-typeahead/css/Typeahead.css";
import useDeviceDimensions from "hooks/useDeviceDimensions";
import JoinGroupButton from "./join-group-button";
import SetGroupStateFromSlug from "app-setup/set-group-state-from-slug";
import SetupMixpanel from "app-setup/mixpanel-init";
import LoginButtons from "components/login-buttons";
import { getPoolsPath } from "utils/backend-path-builders";
import { NOT, POOL_STATUS_PARAM, SUCCEEDED, USERNAME_CUSTOMISATION_POPUP_LIST, } from "utils/constants";
import { getUserState } from "redux/slices/user-slice";
import UsernameForm from "./username-form";
import { local as localStorage } from "utils/local-storage";
import JoinGroupModal from "./join-group-button/join-group-modal";
import { SparketsBanner, ViewMarketplaceButton, } from "@wagerwire/embed/react";
import InitializeIntegrationApp from "app-setup/initialize-integration-app";
import { getIntegratedAppState } from "redux/slices/integrated-app-slice";
import LoadingGroupPage from "components/loading-group-page";
import { getUserGroupSettingsByGroupId, setHomePageEventIdFilter } from "redux/slices/user-settings-slice";
import useQueryParams from "utils/useQueryParams";
import { EVENT_ID_QUERY_PARAM } from "home/home-page-query-parameters";
import { findFirst } from "utils/array-utils";

const Home = (props: any) => {
  const auth = useAuth();
  const queryParams = useQueryParams();

  const [loadingPools, setLoadingPools] = useState(true);
  const [pools, setPools] = useState<Pool[]>([]);
  const group = useAppSelector(getGroupState);
  const [uniqueEvents, setUniqueEvents] = useState<EventIdAndName[]>([]);
  const isIntegratedApp = useAppSelector(getIntegratedAppState).isIntegratedApp;
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [showJoinGroupModal, setShowJoinGroupModal] = useState(false);
  const dispatch = useAppDispatch();
  const groupStatus = useAppSelector(getGroupStatus);
  const userGroupSettings = useAppSelector(state => getUserGroupSettingsByGroupId(state, group.id));
  const user = useAppSelector(getUserState);

  SetupMixpanel();

  const groupSlug = props.match.params.groupSlug;
  SetGroupStateFromSlug(groupSlug);

  InitializeIntegrationApp(groupSlug);

  useEffect(() => {
    const eventIdQueryParam = queryParams.get(EVENT_ID_QUERY_PARAM);
    if (eventIdQueryParam !== null) {
      if (eventIdQueryParam !== "") {
        const eventName = uniqueEvents.find(event => event.id === eventIdQueryParam)?.name;
        if (eventName) {
          dispatch(setHomePageEventIdFilter({ groupId: group.id, eventId: eventIdQueryParam }));
        } else {
          console.warn(`Event id "${eventIdQueryParam}" not found in the list of 
        unique pool events. Unable to set event filter.`);
        }
      } else {
        dispatch(setHomePageEventIdFilter({ groupId: group.id, eventId: "" }));
      }
    }
  }, [queryParams, uniqueEvents]);

  useEffect(() => {
    if (group.id === "") {
      return;
    }
    const path = getPoolsPath(group.id);
    getRequest(path, {
      queryParams: {
        status: POOL_STATUS_PARAM.OPEN,
        pg: 1,
        page_size: 10000,
        sortBy: "weight:desc,close:asc"
      },
      skipIntegrationApi: true
    })
      .then((response) => setPools(response))
      .finally(() => setLoadingPools(false));
  }, [group.id]);

  useEffect(() => {
    const eventIdAndNameByEventId = new Map(
      pools
        .filter(p => p.event_name && p.event_id)
        .map(p => [p.event_id, { id: p.event_id, name: p.event_name } as EventIdAndName])
    );

    const uniqueEvents = Array.from(eventIdAndNameByEventId.values());

    setUniqueEvents(uniqueEvents);
  }, [pools]);

  useEffect(() => {
    if (!auth.signedIn || isIntegratedApp) {
      return;
    }

    const username = user.user_name;

    const sparketGeneratedUsername =
      username.substr(0, 6) === "player" && !isNaN(Number(username.substr(6)));

    const alreadyAskedUsernames =
      localStorage.getItem(USERNAME_CUSTOMISATION_POPUP_LIST)?.split(",") || [];
    if (sparketGeneratedUsername && !alreadyAskedUsernames.includes(username)) {
      setShowUsernameModal(true);
    }
  }, [auth.signedIn, user.user_name]);

  const filteredPools = (() => {
    if (!userGroupSettings?.homePagePoolsEventIdFilter) {
      return pools;
    }
    return pools.filter((pool) => pool.event_id === userGroupSettings.homePagePoolsEventIdFilter);
  })();

  const { isMobile } = useDeviceDimensions();
  const eventFilterWidth = isMobile ? "100%" : "50%";

  const groupHasLoaded = group.id !== "";

  if (!groupHasLoaded) {
    return <LoadingGroupPage />;
  }

  const getSelectOption = () => {
    const homePagePoolsEventIdFilter = userGroupSettings?.homePagePoolsEventIdFilter;
    if (!homePagePoolsEventIdFilter) {
      return null;
    }
    const eventIdAndName = findFirst(uniqueEvents.filter(event => event.id === homePagePoolsEventIdFilter));
    return eventIdAndName ? {
      value: eventIdAndName?.id,
      label: eventIdAndName?.name
    } : null;
  };

  if (
    loadingPools ||
    (!isIntegratedApp &&
      (auth.pending || (auth.signedIn && user.fetchUserState !== SUCCEEDED)))
  ) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <Headroom>
          <div>
            <Header />
            <LoginButtons />
          </div>
        </Headroom>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: 20,
            paddingRight: 20,
            flexWrap: "wrap-reverse",
          }}
        >
          {group.settings.wager_wire && (
            <ViewMarketplaceButton
              groupColor={group.settings.primary_color}
              style={{ width: "100%", padding: 0 }}
              groupId={group.id}
            >
              <SparketsBanner groupColor={group.settings.secondary_color} />
            </ViewMarketplaceButton>
          )}
          {pools.length > 0 && (
            <div
              style={{
                width: eventFilterWidth,
                paddingTop: 10,
                paddingBottom: 5,
              }}
            >
              <Select
                isClearable
                placeholder={"Filter by event"}
                options={Array.from(uniqueEvents).map((event: EventIdAndName) => ({
                  value: event.id,
                  label: event.name
                }))}
                value={getSelectOption()}
                onChange={(selectedEvent) => {
                  if (selectedEvent == null) {
                    dispatch(setHomePageEventIdFilter({ groupId: group.id, eventId: "" }));
                  } else {
                    dispatch(setHomePageEventIdFilter({ groupId: group.id, eventId: selectedEvent.value }));
                  }
                }}
              />
            </div>
          )}
          {auth.signedIn && (
            <div
              style={{
                paddingTop: 10,
                paddingBottom: 5,
                marginLeft: "auto",
              }}
            >
              {!isIntegratedApp && <JoinGroupButton />}
            </div>
          )}
        </div>
        {filteredPools.length > 0 ? (
          <>
            {filteredPools.map((pool) => {
              return (
                <PoolItem
                  key={pool.id}
                  styles={styles}
                  pool={pool}
                  link={`/pools/${pool.id}`}
                  showCountDownClock={true}
                />
              );
            })}
          </>
        ) : (
          <BlockyHeavyText
            customStyles={{
              display: "flex",
              justifyContent: "center",
              color: "white",
            }}
          >
            No pools are currently open. Please check back later.
          </BlockyHeavyText>
        )}
        {auth.signedIn && !isIntegratedApp && (
          <UsernameForm
            close={() => {
              const alreadyAskedUsernames =
                localStorage
                  .getItem(USERNAME_CUSTOMISATION_POPUP_LIST)
                  ?.split(",") || [];
              alreadyAskedUsernames.push(user.user_name);

              localStorage.setItem(
                USERNAME_CUSTOMISATION_POPUP_LIST,
                alreadyAskedUsernames.join(",")
              );

              if (groupStatus === NOT) {
                setShowJoinGroupModal(true);
              }

              setShowUsernameModal(false);
            }}
            show={showUsernameModal}
          />
        )}

        <JoinGroupModal
          acceptAndJoin={() => {
            dispatch(requestToJoinGroup(group.id));
            setShowJoinGroupModal(false);
          }}
          show={showJoinGroupModal}
          close={() => setShowJoinGroupModal(false)}
        />
      </>
    );
  }
};

export default Home;

interface EventIdAndName {
  id: string;
  name: string;
}